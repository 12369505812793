// Libraries
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
// Components
import Container from '../../../Container/Container';
// Icons
import {ReactComponent as Brand} from '@assets/icons/brands.svg';
import {ReactComponent as Bodywork} from '@assets/icons/vehicle.svg';
import {ReactComponent as Card} from '@assets/icons/card.svg';
import {ReactComponent as Property} from '@assets/icons/feature.svg';
import {ReactComponent as Car} from '@assets/icons/car.svg';
import {ReactComponent as Configuration} from '@assets/icons/configuration.svg';
import {ReactComponent as User} from '@assets/icons/user.svg';
import {ReactComponent as Tab} from '@assets/icons/tab.svg';
import {ReactComponent as Help} from '@assets/icons/help.svg';
import {ReactComponent as Logout} from '@assets/icons/logout.svg';
import {ReactComponent as Cars} from '@assets/icons/cars.svg';
// Helpers
import Logo from '@components/Logo/Logo';
// Types
import {Tag} from '../../../../types/tags';
import {RouterPaths} from '@commonTypes/routerPaths';
// Styles
import styles from './Navbar.module.scss';
import {logOutAction} from '@actions/authActions';
import {useDispatch} from 'react-redux';

const Navbar = () => {
	const {pathname} = useLocation();

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpandHandler = () => setIsExpanded(!isExpanded);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const menuItems = [
		{
			id: 'brands',
			label: 'Marki',
			link: RouterPaths.Brands,
			icon: <Brand />,
		},
		{
			id: 'models',
			label: 'Modele',
			link: RouterPaths.Models,
			icon: <Car />,
		},
		{
			id: 'bodies',
			label: 'Zabudowy',
			link: RouterPaths.Bodies,
			icon: <Bodywork />,
		},
		{
			id: 'vehicles',
			label: 'Wersje',
			link: RouterPaths.Vehicles,
			icon: <Cars />,
		},
		{
			id: 'categories',
			label: 'Kategorie cech',
			link: RouterPaths.Categories,
			icon: <Card />,
		},
		{
			id: 'property',
			label: 'Cechy',
			link: RouterPaths.Properties,
			icon: <Property />,
		},
		{
			id: 'tabs',
			label: 'Zakładki',
			link: RouterPaths.Tabs,
			icon: <Tab />,
		},
		{
			id: 'configurations',
			label: 'Oferty',
			link: RouterPaths.Configurations,
			icon: <Configuration />,
		},
		{
			id: 'users',
			label: 'Użytkownicy',
			link: RouterPaths.Users,
			icon: <User />,
		},
	];

	return (
		<Container
			as={Tag.Nav}
			classes={classNames(styles.navbar, {
				[styles.navbarExpanded]: isExpanded,
			})}>
			<Link to={'/'} className={styles.logo} onClick={() => setIsExpanded(false)}>
				<Logo />
			</Link>
			<div className={styles.mobile}>
				<button
					type='button'
					onClick={toggleExpandHandler}
					className={styles.navbarButton}
					aria-label='menu'>
					<span></span>
					<span></span>
				</button>
			</div>
			<div className={styles.navbarMenu}>
				<div>
					{menuItems.map((item) => (
						<Link
							key={item.id}
							className={styles.navbarMenuItem}
							data-is-current={
								item.id === 'brands'
									? pathname === item.link
									: pathname.startsWith(item.link)
							}
							to={item.link}>
							<span className={styles.navbarMenuIcon}>{item.icon}</span>
							{item.label} <span className={styles.navbarMenuCurrent} />
						</Link>
					))}
				</div>
				<div className={styles.utils}>
					<Link className={styles.navbarMenuItem} to='/help'>
						<span className={styles.navbarMenuIcon}>
							<Help />
						</span>
						Pomoc <span className={styles.navbarMenuCurrent} />
					</Link>
					<button
						type='button'
						className={styles.navbarMenuItem}
						onClick={() => {
							dispatch(logOutAction());
							navigate(RouterPaths.Login);
						}}>
						<span className={styles.navbarMenuIcon}>
							<Logout />
						</span>
						Wyloguj się
					</button>
				</div>
			</div>
		</Container>
	);
};

export default Navbar;
