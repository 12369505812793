import {FormField, FormKeys} from '@commonTypes/main';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import React from 'react';
import {initialState} from '../VehicleAddForm/VehicleAddForm';
import Input from '@components/_FormElements/Input/Input';

interface VehicleAddFormDescriptionProps {
	form: FormKeys<typeof initialState>;
	updateFormHandler: (name: string, value: FormField) => void;
}

const VehicleAddFormDescription = (props: VehicleAddFormDescriptionProps) => {
	const {form, updateFormHandler} = props;

	return (
		<div>
			<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
				6. Opisz pojazd
			</Title>
			<Input
				id='description'
				name='description'
				placeholder={'Opis pojazdu'}
				value={form.description.value}
				valueChangeHandler={updateFormHandler}
				label={'Opis pojazdu będzie wyświetlany w podsumowaniu oferty'}
        as='textarea'
				required={form.description.required}
        errorMessage={form.description.errorMessage}
			/>
		</div>
	);
};

export default VehicleAddFormDescription;
